import React, { useState, useEffect, useContext } from 'react'

import NextLink from 'next/link'

import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Grid,
    GridItem,
    Spinner,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import * as XLSX from 'xlsx'

import AppLayout from '@/components/Layouts/AppLayout'
import {
    FastOpportunityModal,
    NewOpportunity,
    OpportunitiesTable,
} from '@/components/opportunities'
import { DeleteModal, PaginationComplex } from '@/components/utils'
import { House } from '@/components/icons'
import { colors } from '@/utils/const'
import { useAuth } from '@/hooks/auth'
import { getALLOpportunities, useOpportunities } from '@/hooks/opportunities'
import { getUsers } from '@/hooks/users'
import { FiltersContext } from '@/context/filters'
import { useRouter } from 'next/router'
import { getExtensionRequestUser } from '@/lib/profiles'

export default function Home() {
    const router = useRouter()
    const [pageIndex, setPageIndex] = useState(1)
    const { user, isLoading } = useAuth({ middleware: 'auth' })
    const {
        opportunities,
        isLoading: dataLoading,
        deleteOpportunity,
        getDistributors,
    } = useOpportunities({ pageIndex })

    const { filters } = useContext(FiltersContext)

    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [idToDelete, setIdToDelete] = useState(null)
    const [users, setUsers] = useState(null)
    const [distributors, setDistributors] = useState(null)

    const [isLoadingAll, setIsLoadingAll] = useState(false)
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()
    const {
        isOpen: isOpenFast,
        onOpen: onOpenFast,
        onClose: onCloseFast,
    } = useDisclosure()

    useEffect(() => {
        if (['admin'].includes(user?.roles[0]?.name)) {
            getUsers({}).then(data => setUsers(data))
        } else {
            setUsers([user])
        }

        getDistributors().then(data => setDistributors(data))
    }, [])

    React.useEffect(() => {
        const { isExtensionRequest, menus } = getExtensionRequestUser(
            user?.profile?.data,
        )
        const hasExtraMenus = Object.values(menus).some(value => value)

        if (hasExtraMenus) return
        else if (isExtensionRequest) router.push('/bonus-extension')
        // eslint-disable-next-line
    }, [])

    const handleModal = id => {
        setIdToDelete(id)
        onOpenDelete()
    }

    const handleCloseModal = () => {
        setIdToDelete(null)
        onCloseDelete()
    }

    const handleDelete = async () => {
        try {
            setIsLoadingDelete(true)

            await deleteOpportunity({ opportunity_id: idToDelete })

            toast({
                title: 'Éxito',
                description: 'Oportunidad eliminada con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIdToDelete(null)
        onCloseDelete()
        setIsLoadingDelete(false)
    }

    const handleExport = async () => {
        setIsLoadingAll(true)
        try {
            const data = await getALLOpportunities({ filters })

            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet(data)

            XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1')

            XLSX.writeFile(wb, 'Oportunidades.xlsx')
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoadingAll(false)
    }

    return (
        <AppLayout>
            <Text
                fontSize={14}
                fontWeight={400}
                color={'#474747'}
                my={4}
                mx={6}>
                Hola <b>{user?.name}</b>, ¡Bienvenido!
            </Text>
            <Grid
                borderY="1px"
                borderColor={'#EBE9F1'}
                gap={4}
                px={6}
                py={4}
                templateColumns={
                    user?.profile?.data?.fast_opportunity === 2
                        ? '1fr 150px 150px'
                        : '1fr 150px'
                }>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}>
                    <BreadcrumbItem>
                        <NextLink href="/">
                            <BreadcrumbLink>
                                <House color={colors.tertiary} />
                            </BreadcrumbLink>
                        </NextLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Text fontSize={14} fontWeight={700} color={'#474747'}>
                            Oportunidades
                        </Text>
                    </BreadcrumbItem>
                </Breadcrumb>

                {[1, 2].includes(user?.profile?.data?.fast_opportunity) && (
                    <GridItem w="100%">
                        <Button
                            fontSize={14}
                            bg={colors.secondary}
                            color="white"
                            h="30px"
                            p={1}
                            w="full"
                            isDisabled={dataLoading}
                            onClick={onOpenFast}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Oportunidad rápida
                        </Button>
                    </GridItem>
                )}

                {user?.profile?.data?.fast_opportunity !== 1 && (
                    <GridItem w="100%">
                        <Button
                            fontSize={14}
                            bg={colors.secondary}
                            color="white"
                            h="30px"
                            p={1}
                            w="full"
                            isDisabled={dataLoading}
                            onClick={onOpen}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            + Nuevo
                        </Button>
                    </GridItem>
                )}
            </Grid>

            <NewOpportunity
                isOpen={isOpen}
                userName={user?.name}
                userProfile={user?.profile}
                onClose={onClose}
                user_id={user?.id}
            />
            <FastOpportunityModal
                user_id={user?.id}
                isOpen={isOpenFast}
                userName={user?.name}
                onClose={onCloseFast}
            />
            <DeleteModal
                title="¿Eliminar oportunidad?"
                body="La eliminación de una oportunidad elimina también el cliente asociado."
                isLoading={isLoadingDelete}
                isOpen={isOpenDelete}
                handleCloseModal={handleCloseModal}
                handleDelete={handleDelete}
            />
            <Box display="flex" justifyContent="center" p={4} w="full">
                {isLoadingAll ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        h="200px"
                        w="full">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Box>
                ) : (
                    <OpportunitiesTable
                        userRol={user?.roles[0]?.name || ''}
                        data={opportunities?.data}
                        meta={opportunities?.meta}
                        setPageIndex={setPageIndex}
                        isLoading={dataLoading}
                        users={users}
                        distributors={distributors}
                        handleDelete={handleModal}
                        handleExport={handleExport}
                    />
                )}
            </Box>
        </AppLayout>
    )
}
